import './style/style.css';

function App() {
  return (
    <div>
      <img src="/img/intro.png" alt="Coming soon" className="centre" />
    </div>
  );
}

export default App;
